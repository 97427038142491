/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // var selectArrowIcon = document.querySelector(".menu-37");

        // selectArrowIcon.classList.add("product__select-image");

        // console.log(selectArrowIcon);

        // JavaScript to be fired on the home page

        var liveDateText = document.querySelector(".live__date");
        var date = new Date().getTime();

        if (date <= 1599541200000) {
          liveDateText.textContent = "08 de septiembre de 2020.";
        } else if (date > 1599541200000 && date <= 1600750800000) {
          liveDateText.textContent = "22 de septiembre de 2020.";
        } else if (date > 1600750800000 && date <= 1601960400000) {
          liveDateText.textContent = "06 de octubre de 2020.";
        } else if (date > 1601960400000 && date <= 1603170000000) {
          liveDateText.textContent = "20 de octubre de 2020.";
        } else if (date > 1603170000000 && date <= 1604556000000) {
          liveDateText.textContent = "05 de noviembre de 2020.";
        }

        $(".owl-carousel").owlCarousel({
          items: 1,
          margin: 0,
          slideBy: 1,
          mouseDrag: false,
          dots: 0,
          nav: true,
          loop: true,
          navText: [
            '<img src="/wp-content/themes/vermeer-campaign/dist/images/Icon/Arrow_left.svg"/>',
            '<img src="/wp-content/themes/vermeer-campaign/dist/images/Icon/Arrow_right.svg"/>',
          ],
          responsive: {
            780: {
              items: 3,
              margin: 20,
            },
          },
        });

        // var topOffset = 60;
        // $('a[href*="#"]').click(function () {
        //   if (
        //     location.pathname.replace(/^\//, "") ==
        //       this.pathname.replace(/^\//, "") &&
        //     location.hostname == this.hostname
        //   ) {
        //     var target = $(this.hash);
        //     target = target.length
        //       ? target
        //       : $("[name=" + this.hash.slice(1) + "]");
        //     if (target.length) {
        //       $("html, body").animate(
        //         {
        //           scrollTop: target.offset().top - topOffset,
        //         },
        //         1000
        //       );
        //       return false;
        //     }
        //   }
        // });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
